
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "relationship-type",
  components: { Field, ErrorMessage },
  props: {
    widgetClasses: String,
    selectedOption: String
  },
  data() {
    return {
      relationshipTypes: ""
    };
  },
  mounted() {
    this.getRelationshipTypes();
  },
  methods: {
    getRelationshipTypes() {
      //Fetch list of relationship types from the database
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.RELATIONSHIP_TYPE_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.relationshipTypes = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    capitalize(value) {
      return variables.capitalize(value);
    }
  }
});
